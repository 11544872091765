import React from "react";
import Container from "../../bulma/Container";
import {Translations as T} from "../../../res/translations";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../res/routes";

interface OrderNoUserProps {
    lang: string;
    product: string;
}

const OrderNoUser = ({lang, product}: OrderNoUserProps) => {
    const returnState = { returnUrl: ROUTES[lang].order + '' + product };
    return (
            <Container>
                <div className="box">
                    <p className="subtitle is-4">{T[lang].order.newCustomer}.</p>
                    <Link to={ROUTES[lang].register} state={returnState}>
                        <button className="button is-success">{T[lang].register.title}</button>
                    </Link>
                </div>

                <div className="box mt-6">
                    <p className="subtitle is-4">{T[lang].order.existCustomer}.</p>
                    <Link to={ROUTES[lang].login} state={returnState} >
                        <button className="button is-success is-outlined">{T[lang].login.title}</button>
                    </Link>
                </div>
            </Container>

    );
}

export default OrderNoUser;
