import React, {useState} from "react";
import axios from "axios";
import Container from "../bulma/Container";
import PageHeading from "../bulma/PageHeading";
import SimpleCard from "../bulma/SimpleCard";
import Notification from "../bulma/Notification";
import {Translations as T} from '../../res/translations';
import Layout from "../layout";
import Field from "../input/Field";
import {api} from "../../config/config";
import {API_ROUTES} from "../../res/routes";
import authService from "../../services/authService";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {AuthResponse} from "../../interfaces/AuthResponse";

interface LoginPageProps {
    lang: string;
}

interface LocationState {
    returnUrl: string;
}

const LoginPage = ({lang}: LoginPageProps): JSX.Element => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {state} = useLocation();
    const navigate = useNavigate();

    const handleLogin = () => {
        setIsLoading(true);
        setError('');
        const payload = {
            "username": username,
            "password": password,
        }

        axios.post<AuthResponse>(api.url + API_ROUTES.login, payload)
            .then(function (response) {
                setIsLoading(false);
                authService.storeTokens(response.data.token, response.data.refreshToken, response.data.expires);
                state && state.returnUrl ? navigate(state.returnUrl) : navigate('/');
                return false;
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
                if (error.response.status === 401) {
                    setError(T[lang].login.errors.pworuname);
                } else {
                    setError(error.toString());
                }
            });
    }

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={T[lang].login.title} />
                <SimpleCard>
                    <Field
                        value={username}
                        caption={T[lang].login.username}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        variant="username"
                    />
                    <Field
                        value={password}
                        caption={T[lang].login.password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        variant="password"
                    />
                    <button
                        className={'button is-primary mt-5' + (isLoading ? ' is-loading' : '')}
                        onClick={() => handleLogin()}
                        disabled={isLoading}
                    >
                        {T[lang].login.title}
                    </button>
                </SimpleCard>

                <div className="error-container">
                    {
                        error !== '' &&
                        <Notification>
                            <span>{error}</span>
                        </Notification>
                    }
                </div>
            </Container>
        </Layout>
    );

}

export default LoginPage;
