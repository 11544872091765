import React, {useState} from "react";
import axios from "axios";
import Container from "../../bulma/Container";
import PageHeading from "../../bulma/PageHeading";
import SimpleCard from "../../bulma/SimpleCard";
import {Translations as T} from '../../../res/translations';
import Layout from "../../layout";
import Field from "../../input/Field";
import {api} from "../../../config/config";
import {API_ROUTES, PORTAL_RESET_POST} from "../../../res/routes";
import {useParams, useSearchParams} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {AuthResponse} from "../../../interfaces/AuthResponse";
import ErrorContainer from "../../bulma/ErrorContainer";
import ErrorType from "../../../interfaces/ErrorType";
import errorHandler from "../../../services/errorHandler";

interface ResetPasswordPageProps {
    lang: string;
}

const ResetPasswordPage = ({lang}: ResetPasswordPageProps): JSX.Element => {
    const {code, site} = useParams();
    const [error, setError] = useState<ErrorType | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get('token');
    const {state} = useLocation();

    const hasResetToken = (): boolean => resetToken !== null;
    const setProcessing = () => {
        setIsLoading(true);
        setError(null);
    }

    // handle enter key
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (hasResetToken()) handleReset();
            else handleResetRequest();
        }
    }

    const handleResetRequest = () => {
        setProcessing();
        if (username === '') {
            setIsLoading(false);
            setError(errorHandler.customError(T[lang].auth.usernameRequired));
            return false;
        }
        const payload = {
            "username": username,
        }
        const route = api.url + API_ROUTES.user.resetpasswordrequest;

        axios.post(route, payload)
            .then(function (response) {
                setIsLoading(false);
                setIsRequested(true);
                return false;
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response && error.response.status === 412) {
                    setError(errorHandler.customError(T[lang].auth.multipleAccounts));
                    return false;
                }
                setError(errorHandler.requestError(error));
            });
    }

    const handleReset = () => {
        setProcessing();
        const payload = {
            "password": password,
            "resetToken": resetToken,
        }
        const route = api.url + API_ROUTES.user.resetpassword;

        axios.post<AuthResponse>(route, payload)
            .then(function (response) {
                setIsLoading(false);
                setIsSuccess(true);
                return false;
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response && error.response.status === 409) {
                    setError(errorHandler.customError(T[lang].auth.resetUsed));
                    return false;
                }
                setError(errorHandler.requestError(error));
            });
    }

    const passwordResetRequested =
        <SimpleCard>
            <h2 className="title is-4">{T[lang].auth.passwordResetRequested}</h2>
            <p className="is-size-6">{T[lang].auth.passwordResetRequestedMsg}</p>
        </SimpleCard>

    const passwordUpdated =
        <SimpleCard>
            <h2 className="title is-4">{T[lang].auth.passwordUpdated}</h2>
            <p className="is-size-6">{T[lang].auth.passwordUpdatedMsg}</p>
            <a href="https://scanstockphoto.com/signin.php" className="button is-primary mt-6">
                <button className="button is-primary">
                    {T[lang].login.title}
                </button>
            </a>
        </SimpleCard>

    const resetRequestForm =
        <SimpleCard>
            <p className="is-size-5 mb-6">
                {T[lang].auth.resetinfo}
            </p>
            <Field
                value={username}
                caption={T[lang].login.username + ' / ' + T[lang].misc.email}
                onChange={(e) => setUsername(e.currentTarget.value)}
                onKeyDown={(e) => handleKeyPress(e)}
                variant="username"
                isRequired={true}
            />
            <p className="is-light">
                {T[lang].auth.resetemailinfo}
            </p>
            <button
                className={'button is-primary mt-6' + (isLoading ? ' is-loading' : '')}
                onClick={() => handleResetRequest()}
                disabled={isLoading}
            >
                {T[lang].misc.continue}
            </button>
        </SimpleCard>

    const resetForm =
        <>
        <SimpleCard>
            <p className="is-size-5 pb-2">
                {T[lang].auth.enterNewPassword}
            </p>
            <Field
                value={password}
                caption={T[lang].login.password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                variant="password"
            />
            <Field
                value={password2}
                caption={T[lang].login.repeatpassword}
                onChange={(e) => setPassword2(e.currentTarget.value)}
                variant="password"
            />
            <button
                className={'button is-primary mt-5' + (isLoading ? ' is-loading' : '')}
                onClick={() => handleReset()}
                disabled={isLoading}
            >
                {T[lang].auth.changePassword}
            </button>
        </SimpleCard>
        </>

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={T[lang].auth.resetpassword} />
                <>
                    {isSuccess && passwordUpdated}
                    {!isRequested && !isSuccess && !hasResetToken() && resetRequestForm}
                    {isRequested && passwordResetRequested}
                    {!isSuccess && resetToken && resetForm}
                    {
                        error &&
                            <ErrorContainer title={error?.title} message={error?.message} status={error?.status} />
                    }
                </>
            </Container>
        </Layout>
    );
}

export default ResetPasswordPage;
