import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import IndexPage from './components/pages/frontpage/IndexPage';
import RegisterPage from './components/pages/RegisterPage';
import DownloadPage from "./components/pages/account/DownloadPage";
import PhotoPage from "./components/pages/photo/PhotoPage";
import PricePage from "./components/pages/Order/PricePage";
import OrderPage from "./components/pages/Order/OrderPage";
import PageNotFound from "./components/pages/PageNotFound";
import AccountPage from "./components/pages/account/AccountPage";
import LogoutPage from "./components/pages/LogoutPage";
import ResetPasswordPage from "./components/pages/auth/ResetPassword";

const AppRouter = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route path="/" element={<IndexPage lang="en" />} />*/}
                <Route path="/" element={<RegisterPage lang="en" />} />
                <Route path="/login" element={<LoginPage lang="en" />} />
                <Route path="/reset-password" element={<ResetPasswordPage lang="en" />} />
                <Route path="/logout" element={<LogoutPage lang="en" />} />
                <Route path="/account" element={<AccountPage lang="en" />} />
                <Route path="/account.php" element={<AccountPage lang="en" />} />
                <Route path="/pricing" element={<PricePage lang="en" />} />
                <Route path="/buy.php" element={<PricePage lang="en" />} />
                <Route path="/order/:product" element={<OrderPage lang="en" />} />
                <Route path="/register" element={<RegisterPage lang="en" />} />
                <Route path="/downloads" element={<DownloadPage lang="en" />} />
                <Route path="/photo/:id" element={<PhotoPage lang="en" />} />
                <Route path="/no">
                    {/*<Route index={true} element={<IndexPage lang="no" />} />*/}
                    <Route index={true} element={<RegisterPage lang="no" />} />
                    <Route path="login" element={<LoginPage lang="no" />} />
                    <Route path="reset-password" element={<ResetPasswordPage lang="no" />} />
                    <Route path="logout" element={<LogoutPage lang="no" />} />
                    <Route path="account" element={<AccountPage lang="no" />} />
                    <Route path="pricing" element={<PricePage lang="no" />} />
                    <Route path="order/:product" element={<OrderPage lang="no" />} />
                    <Route path="pricing" element={<PricePage lang="no" />} />
                    <Route path="register" element={<RegisterPage lang="no" />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
