import authService from "../../services/authService";
import Layout from "../layout";
import Container from "../bulma/Container";
import Box from "../bulma/Box";

interface LogoutPageProps {
    lang: string;
}

const LogoutPage = ({lang}: LogoutPageProps) => {
    authService.logout();
    return (
        <Layout lang={lang} >
            <Container>
                <Box>
                    <p>Du er logget ut. Takk for nå</p>
                </Box>
            </Container>
        </Layout>
    );
}

export default LogoutPage;
