import React from "react";

const dividers: {[index: string]:any} = {
    '1': ' is-full',
    '2': ' is-half',
    '3': ' is-one-third',
    '4': ' is-one-quarter',
}

interface ColumnProps {
    children?: JSX.Element|JSX.Element[];
    col: string;
}

const Column = ({children, col}: ColumnProps) => {
    let colClass = '';
    if (col) colClass = dividers[col];

    return (
        <div className={'column' + colClass}>
            {children}
        </div>
    )
}

export default Column;
