export default class authService {

    static storeTokens(token: string, refreshToken: string, expires: Date) {
        this.setToken(token);
        this.setRefreshToken(refreshToken);
        this.setExpires(expires);
    }

    static clearTokens() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('tokenExpires');
    }

    static setToken(token: string) {
        window.localStorage.setItem('token', token);
    }

    static getToken(): string|null {
        return window.localStorage.getItem('token');
    }

    static setRefreshToken(token: string) {
        window.localStorage.setItem('refreshToken', token);
    }

    static setExpires(expires: Date) {
        window.localStorage.setItem('tokenExpires', expires.toString());
    }

    static isLogin(): boolean {
        const token = this.getToken();
        return !!token;
    }

    static logout() {
        this.clearTokens();
    }
}
