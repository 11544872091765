import React from "react";

interface SimpleCardProps {
    children?: JSX.Element|JSX.Element[];
}

const SimpleCard = ({children}: SimpleCardProps): JSX.Element =>
    <div className="card">
        <div className="card-content">
            {children}
        </div>
    </div>

export default SimpleCard;
