export const api = {
    url: process.env.REACT_APP_RUN_ENV === 'dev' ? 'http://localhost:8080' : 'https://ssp-api-e4euxfebna-ez.a.run.app'
};

export const portal = {
    url: process.env.REACT_APP_RUN_ENV === 'dev' ? 'http://localhost:8081' : 'https://app.scanstockphoto.com'
};

export const googleImg = {
    thumbSm: 'https://storage.googleapis.com/scanstock-old-thumbs/'
}

export const maxTitleLength = 50;
