export default class PhotoService {

    static parsePhotoId = (photoId: string): number => {
        const reg = /^\d+$/;
        try {
            if (!reg.test(photoId)) throw new Error('Photo id is not a number');
            const id = parseInt(photoId);
            if (isNaN(id)) throw new Error('Photo id is not a number');
            return id;
        }
        catch (e) {
            return 0
        }
    }
}