import React from "react";

interface KeywordPanelProps {
    keywords?: string[];
}

const KeywordPanel = ({keywords}: KeywordPanelProps): JSX.Element => {
    const keywordList = keywords?.map((keyword: string) => {
        return (
            <span className="tag">{keyword}</span>
        );
    });

    return (
        <div className="tags pt-5">
            {keywordList}
        </div>
    );
}

export default KeywordPanel;
