export const defaultPrice = [1,10,50,100];
export const mostPopular = 10;
export const isoCurrency = new Map<string, string>([
    ['NOK', 'kr'],
    ['EUR', '€'],
    ['SEK', 'kr'],
    ['DKK', 'kr'],
    ['USD', "$"],
    ['GBP', "£"],
]);

export const currencies = ['DKK', 'EUR', 'GBP', 'NOK', 'SEK', 'USD'];

export const pricing =
    [
        {
            "quantity": 1,
            "productid": "c1",
            "price": 14.90,
            "currency": "EUR"
        },
        {
            "quantity": 1,
            "productid": "c1sek",
            "price": 139.00,
            "currency": "SEK"
        },
        {
            "quantity": 1,
            "productid": "c1us",
            "price": 8.80,
            "currency": "USD"
        },
        {
            "quantity": 1,
            "productid": "c1dk",
            "price": 109.00,
            "currency": "DKK"
        },
        {
            "quantity": 1,
            "productid": "c1no",
            "price": 119.00,
            "currency": "NOK"
        },
        {
            "quantity": 1,
            "productid": "c1gb",
            "price": 9.90,
            "currency": "GBP"
        },
        {
            "quantity": 2,
            "productid": "c2",
            "price": 19.90,
            "currency": "EUR"
        },
        {
            "quantity": 2,
            "productid": "c2us",
            "price": 16.90,
            "currency": "USD"
        },
        {
            "quantity": 2,
            "productid": "c2dk",
            "price": 149.00,
            "currency": "DKK"
        },
        {
            "quantity": 2,
            "productid": "c2no",
            "price": 169.00,
            "currency": "NOK"
        },
        {
            "quantity": 2,
            "productid": "c2gb",
            "price": 14.90,
            "currency": "GBP"
        },
        {
            "quantity": 2,
            "productid": "c2sek",
            "price": 179.00,
            "currency": "SEK"
        },
        {
            "quantity": 5,
            "productid": "c5no",
            "price": 279.00,
            "currency": "NOK"
        },
        {
            "quantity": 5,
            "productid": "c5sek",
            "price": 319.00,
            "currency": "SEK"
        },
        {
            "quantity": 5,
            "productid": "c5us",
            "price": 39.00,
            "currency": "USD"
        },
        {
            "quantity": 5,
            "productid": "c5dk",
            "price": 259.00,
            "currency": "DKK"
        },
        {
            "quantity": 5,
            "productid": "c5",
            "price": 34.90,
            "currency": "EUR"
        },
        {
            "quantity": 5,
            "productid": "c5gb",
            "price": 24.00,
            "currency": "GBP"
        },
        {
            "quantity": 10,
            "productid": "c10no",
            "price": 399.00,
            "currency": "NOK"
        },
        {
            "quantity": 10,
            "productid": "c10",
            "price": 39.00,
            "currency": "EUR"
        },
        {
            "quantity": 10,
            "productid": "c10sek",
            "price": 399.00,
            "currency": "SEK"
        },
        {
            "quantity": 10,
            "productid": "c10dk",
            "price": 299.00,
            "currency": "DKK"
        },
        {
            "quantity": 10,
            "productid": "c10gb",
            "price": 29.00,
            "currency": "GBP"
        },
        {
            "quantity": 10,
            "productid": "c10us",
            "price": 44.00,
            "currency": "USD"
        },
        {
            "quantity": 15,
            "productid": "c15dk",
            "price": 369.00,
            "currency": "DKK"
        },
        {
            "quantity": 15,
            "productid": "c15sek",
            "price": 495.00,
            "currency": "SEK"
        },
        {
            "quantity": 15,
            "productid": "c15",
            "price": 49.00,
            "currency": "EUR"
        },
        {
            "quantity": 15,
            "productid": "c15no",
            "price": 399.00,
            "currency": "NOK"
        },
        {
            "quantity": 30,
            "productid": "c30sek",
            "price": 1194.00,
            "currency": "SEK"
        },
        {
            "quantity": 30,
            "productid": "c30us",
            "price": 129.00,
            "currency": "USD"
        },
        {
            "quantity": 30,
            "productid": "c30gb",
            "price": 84.00,
            "currency": "GBP"
        },
        {
            "quantity": 30,
            "productid": "c30dk",
            "price": 895.00,
            "currency": "DKK"
        },
        {
            "quantity": 30,
            "productid": "c30no",
            "price": 1139.00,
            "currency": "NOK"
        },
        {
            "quantity": 30,
            "productid": "c30",
            "price": 114.00,
            "currency": "EUR"
        },
        {
            "quantity": 50,
            "productid": "c50us",
            "price": 199.00,
            "currency": "USD"
        },
        {
            "quantity": 50,
            "productid": "c50dk",
            "price": 1399.00,
            "currency": "DKK"
        },
        {
            "quantity": 50,
            "productid": "c50gb",
            "price": 129.00,
            "currency": "GBP"
        },
        {
            "quantity": 50,
            "productid": "c50no",
            "price": 1595.00,
            "currency": "NOK"
        },
        {
            "quantity": 50,
            "productid": "c50sek",
            "price": 1795.00,
            "currency": "SEK"
        },
        {
            "quantity": 50,
            "productid": "c50",
            "price": 185.00,
            "currency": "EUR"
        },
        {
            "quantity": 100,
            "productid": "c100sek",
            "price": 3490.00,
            "currency": "SEK"
        },
        {
            "quantity": 100,
            "productid": "c100dk",
            "price": 1999.00,
            "currency": "DKK"
        },
        {
            "quantity": 100,
            "productid": "c100gb",
            "price": 249.00,
            "currency": "GBP"
        },
        {
            "quantity": 100,
            "productid": "c100us",
            "price": 390.00,
            "currency": "USD"
        },
        {
            "quantity": 100,
            "productid": "c100no",
            "price": 2995.00,
            "currency": "NOK"
        },
        {
            "quantity": 100,
            "productid": "c100",
            "price": 359.00,
            "currency": "EUR"
        },
        {
            "quantity": 200,
            "productid": "c200gb",
            "price": 479.00,
            "currency": "GBP"
        },
        {
            "quantity": 200,
            "productid": "c200dk",
            "price": 3990.00,
            "currency": "DKK"
        },
        {
            "quantity": 200,
            "productid": "c200no",
            "price": 5695.00,
            "currency": "NOK"
        },
        {
            "quantity": 200,
            "productid": "c200us",
            "price": 760.00,
            "currency": "USD"
        },
        {
            "quantity": 200,
            "productid": "c200",
            "price": 699.00,
            "currency": "EUR"
        },
        {
            "quantity": 200,
            "productid": "c200sek",
            "price": 6390.00,
            "currency": "SEK"
        },
        {
            "quantity": 400,
            "productid": "c400dk",
            "price": 7799.00,
            "currency": "DKK"
        },
        {
            "quantity": 400,
            "productid": "c400sek",
            "price": 11960.00,
            "currency": "SEK"
        },
        {
            "quantity": 400,
            "productid": "c400",
            "price": 1359.00,
            "currency": "EUR"
        },
        {
            "quantity": 400,
            "productid": "c400no",
            "price": 9990.00,
            "currency": "NOK"
        },
        {
            "quantity": 400,
            "productid": "c400us",
            "price": 1480.00,
            "currency": "USD"
        },
        {
            "quantity": 400,
            "productid": "c400gb",
            "price": 919.00,
            "currency": "GBP"
        },
        {
            "quantity": 1000,
            "productid": "c1000sek",
            "price": 28990.00,
            "currency": "SEK"
        },
        {
            "quantity": 1000,
            "productid": "c1000",
            "price": 3349.00,
            "currency": "EUR"
        },
        {
            "quantity": 1000,
            "productid": "c1000dk",
            "price": 18999.00,
            "currency": "DKK"
        },
        {
            "quantity": 1000,
            "productid": "c1000no",
            "price": 19900.00,
            "currency": "NOK"
        },
        {
            "quantity": 1000,
            "productid": "c1000gb",
            "price": 1990.00,
            "currency": "GBP"
        },
        {
            "quantity": 1000,
            "productid": "c1000us",
            "price": 3599.00,
            "currency": "USD"
        }
    ];
