import React from "react";
import Layout from "../../layout";
import Container from "../../bulma/Container";
import SimpleCard from "../../bulma/SimpleCard";
import PriceTable from "./PriceTable";
import PageHeading from "../../bulma/PageHeading";
import {Translations as T} from "../../../res/translations";

interface PricePageProps {
    lang: string;
}

const PricePage = ({lang}: PricePageProps) => {
    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={T[lang].order.pricing} />
                <SimpleCard>
                    <PriceTable currency="NOK" showAll={false} lang={lang} />
                </SimpleCard>
            </Container>
        </Layout>
    );
}

export default PricePage;
