import React from "react";

interface SimpleCardProps {
    caption: string;
}

const Label = ({caption}: SimpleCardProps): JSX.Element => {
    return (
        <label>{caption}</label>
    );
}

export default Label;
