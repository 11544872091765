import React, {useState} from "react";
import priceService from "../../../services/priceService";
import {Translations as T} from '../../../res/translations';
import PriceRow from "./PriceRow";

interface PriceTableProps {
    currency: string;
    showAll: boolean;
    lang: string;
}

const PriceTable = ({currency, lang, showAll = false}: PriceTableProps): JSX.Element => {
    const showAllClass = 'has-text-info has-text-weight-semibold';
    const [isShowAll, setIsShowAll] = useState<boolean>(showAll);
    const prices = priceService.getPriceListCurrency(currency, isShowAll);
    const priceList = prices.map((price) => {
        return (
            <PriceRow price={price} lang={lang} />
        );
    });

    return (
        <table className="table">
            <thead>
            <tr>
                <th>{T[lang].order.imageCount}</th>
                <th>{T[lang].order.unitPrice}</th>
                <th>{T[lang].order.price}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {priceList}
                <tr>
                    <td colSpan={4}>
                        {isShowAll ?
                            <p className={showAllClass} onClick={() => setIsShowAll(false)}>{T[lang].misc.showLess}</p>
                            :
                            <p className={showAllClass} onClick={() => setIsShowAll(true)}>{T[lang].misc.showAll}</p>
                        }
                        <p className="pt-3">
                            {T[lang].order.inCurrency} <strong>{currency}</strong>.
                            {currency === "NOK" && <span className="pl-2">{T[lang].order.norVatInfo}</span>}
                        </p>
                        <p className="pt-2">{T[lang].order.twoYRule}.</p>
                        <img className="pt-2" alt="Du kan bruke Mastercard, Visa eller American Express" src="https://storage.googleapis.com/scanstock-static/mc_vs_ax_card.gif" />

                    </td>
                </tr>
            </tbody>

        </table>
    );
}

export default PriceTable;
