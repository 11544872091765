import {defaultPrice, pricing} from "../res/Pricing";
import {Product} from "../types/Product";


export default class urlService {

    static getPriceListCurrency(currency: string, all: boolean): Product[] {
        if (all) return pricing.filter(price => price.currency === currency);
        return pricing.filter(price => price.currency === currency && defaultPrice.indexOf(price.quantity) !== -1);
    }

    static getImageData(imageId: number): string {
        return '/' + imageId.toString() + '/image';
    }

}