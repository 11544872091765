import React from "react";

interface ImageLevelProps {
    imageId?: number;
    autorName?: string;
}

const ImageLevel = ({imageId, autorName}: ImageLevelProps) =>
    <nav className="level">
        <div className="level-item has-text-centered">
            <div>
                <p className="heading">Contributer</p>
                <p className="is-size-5">{autorName}</p>
            </div>
        </div>
        <div className="level-item has-text-centered">
            <div>
                <p className="heading">Bilde nr</p>
                <p className="is-size-5">{imageId}</p>
            </div>
        </div>
        <div className="level-item has-text-centered">
            <div>
                <p className="heading">Image Cost</p>
                <p className="is-size-5">1 download credit</p>
                <p className="is-size-7">You have 23 credits</p>
            </div>
        </div>
        <div className="level-item has-text-centered">
            <div>
                <p className="heading">File type</p>
                <p className="is-size-5">jpeg</p>
            </div>
        </div>
    </nav>

export default ImageLevel;
