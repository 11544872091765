import {format, parseISO} from 'date-fns';
import {nb} from "date-fns/locale";

const standardDateFormat = 'dd. MMM yyyy';
const standardDateTimeFormat = 'dd. MMM yyyy HH:mm';

export default class TimeDateService {

    static renderDate = (date: Date): string => format(new Date(date), standardDateTimeFormat);

}
