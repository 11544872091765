import React from "react";
import {Product} from "../../../types/Product";
import PaymentOptions from "./PaymentOptions";

interface OrderPanelProps {
    lang: string;
    product?: Product;
}

const OrderPanel = ({lang, product}: OrderPanelProps): JSX.Element => {

        return (
            <div className="row">
                <div className="col-md-6 align-self-center">
                    <PaymentOptions />
                </div>
            </div>
        );

}

export default OrderPanel;
