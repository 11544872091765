import React from 'react';
import './App.css';
import AppRouter from './AppRouter';

function App() {
  return (
      <AppRouter />
  );
}

export default App;
