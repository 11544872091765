import React, {useEffect, useState} from "react";
import Layout from "../../layout";
import Container from "../../bulma/Container";
import PageHeading from "../../bulma/PageHeading";
import SimpleCard from "../../bulma/SimpleCard";
import Notification from "../../bulma/Notification";
import {api, maxTitleLength} from "../../../config/config";
import {API_ROUTES, ROUTES} from "../../../res/routes";
import {Translations as T} from '../../../res/translations';
import authService from "../../../services/authService";
import timeDateService from "../../../services/timeDateService";
import urlService from "../../../services/urlService";
import {callApi} from "../../../api/ssp";
import { v4 as uuidv4 } from 'uuid';
import {Link} from "react-router-dom";
import {DownloadsReq} from "../../../interfaces/DownloadsReq";
import {Download} from "../../../interfaces/download";
import errorHandler from "../../../services/errorHandler";

interface DownloadsPageProps {
    lang: string;
}

const DownloadsPage = ({lang}: DownloadsPageProps) => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [page, setPage] = useState<number>(0);
    // const [size, setSize] = useState<number>(100);
    const [downloadCount, setDownloadCount] = useState<number>(0);
    const [downloads, setDownloads] = useState<Download[]>([]);
    // const navigate = useNavigate();

    const config = {
        headers:{
            Token: authService.getToken(),
        }
    };

    const handleGetDownloads = () => {
        setIsLoading(true);
        setError('');
        callApi.get<DownloadsReq>(api.url + API_ROUTES.account.downloads, config)
            .then(res => {
                setIsLoading(false);
                setDownloadCount(res.data.totalCount);
                setDownloads(res.data.data);
            })
            .catch(error => {
                /*
                if (axios.isAxiosError(error)) {
                    const axiosError = error as AxiosError;
                    if (axiosError.status === 401) {
                        navigate('/login');
                    }
                }
                */
                errorHandler.requestError(error);
                setError("Nettverksforbindelse med server mangler. Enten er vår server ikke tilgjengelig eller så mangler du internettforbindelse.");
            });
    }

    useEffect(() => {
        handleGetDownloads();
    }, [])

    const downloadList = downloads?.map((download) => {
        //const custRef = download.custRef != '' ? <p>Cust ref<p/> : <span></span>;
        console.log(download);
        return (
            <tr key={uuidv4()}>
                <td width={200}>
                    <Link to={ROUTES[lang].photo + download.id}>
                        <img loading="lazy" src={urlService.getThumbSm(download.id)} alt={download.title} />
                    </Link>
                </td>
                <td>
                    {download.title.slice(0, maxTitleLength)}
                </td>
                <td>
                    {download.copyrightName}
                </td>
                <td>
                    {download.fromCust}
                </td>
                <td>
                    {timeDateService.renderDate(download.transTime)}
                </td>
            </tr>
        );
    });

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={T[lang].downloadpage.title} />
                <p>Her er historikken din</p>
                <p>Antall nedlastninger: {downloadCount}</p>
                <SimpleCard>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Artist</th>
                            <th>Credits used</th>
                            <th>Download date</th>
                        </tr>
                        </thead>
                        <tbody>
                            {downloadList}
                        </tbody>
                    </table>
                </SimpleCard>

                <div className="error-container">
                    {
                        error !== '' &&
                        <Notification>
                            <p>{error}</p>
                        </Notification>
                    }
                </div>
            </Container>
        </Layout>
    );
}

export default DownloadsPage;
