import React from "react";

interface ContainerProps {
    children?: JSX.Element|JSX.Element[];
}

const Container = ({children}: ContainerProps): JSX.Element =>
    <div className="container">
        {children}
    </div>

export default Container;
