import { redirect } from "react-router-dom";
import axios, {AxiosError} from "axios";
import ErrorType from "../interfaces/ErrorType";
import {Translations as T} from "../res/translations";
import {ROUTES} from "../res/routes";

export default class errorHandler {

    static requestError(error: any, lang: string = "en"): ErrorType {
        const networkError = "Network Error";
        if (this.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.status === 401) {
                redirect(ROUTES[lang].login)
            }
            if (axiosError.message === networkError) {
                return {
                    title: T[lang].error.nettwork,
                    message: T[lang].error.networkmsg,
                    status: networkError
                };
            }
            if (axiosError.response?.status === 500) {
                return {
                    title: T[lang].error.serverErrorTitle,
                    message: T[lang].error.serverError,
                    status: '500',
                }
            }
            return {
                title: T[lang].error.nettwork,
                message: axiosError.message = networkError ? "Nettverksforbindelse med server mangler. Enten er vår server ikke tilgjengelig eller så mangler du internettforbindelse." : axiosError.message,
                status: axiosError.status ? axiosError.status.toString() : "500"
            };
        }
        return {
            title: T[lang].error.nettwork,
            message: "Nettverksforbindelse med server mangler. Enten er vår server ikke tilgjengelig eller så mangler du internettforbindelse.",
            status: "500"
        };
    }

    static customError(title: string, message?: string, status?: string): ErrorType {
        return {
            title: title,
            message: message ? message : '',
            status: status ? status : ''
        };
    }

    static isAxiosError(error: any): boolean {
        return axios.isAxiosError(error);
    }

}