import React from "react";
import {isoCurrency, mostPopular} from "../../../res/Pricing";
import {Product} from "../../../types/Product";
import {Translations as T} from "../../../res/translations";
import {Link} from "react-router-dom";

export enum PriceCellKind {
    Qty = 'quantity',
    Price = 'price',
    UnitPrice = 'unitprice',
    Order = 'order',
}

interface PriceCellProps {
    price: Product;
    kind: PriceCellKind;
    lang: string;
}

const PriceCell = ({price, kind, lang}: PriceCellProps): JSX.Element => {
    const isPopular = price.quantity === mostPopular;
    switch (kind) {
        case PriceCellKind.Qty:
            return (
                <td align="left">
                    {price.quantity}
                </td>
            );
        case PriceCellKind.Price:
            return (
                <td align="right" className="has-text-weight-semibold">
                    {price.price} {isoCurrency.get(price.currency)}
                </td>
            );
        case PriceCellKind.UnitPrice:
            return (
                <td align="right" className="has-text-weight-normal">
                    {(price.price/price.quantity).toFixed(2)} {isoCurrency.get(price.currency)}
                </td>
            );
        case PriceCellKind.Order:
            return (
                <td align="left" className="has-text-weight-normal">
                    <Link to={"/order/" + price.productid}>
                        <button className={"button is-small " + (isPopular ? "is-success" : "is-outline")}>{T[lang].order.buy}</button>
                    </Link>
                    {isPopular ? <span className="has-text-weight-semibold has-text-danger pl-3">{T[lang].order.popluar}!</span> : ""}
                </td>
            );
    }
}

export default PriceCell;
