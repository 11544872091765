import axios from 'axios';
import {api} from '../config/config';

export const callApi = axios.create({
    baseURL: api.url,
    timeout: 1000,
});

callApi.interceptors.request.use(function (config) {
    console.log('Sending a request:', config.url);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
