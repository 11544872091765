const validLetters = /^[a-zA-Z0-9._+\-@]+$/;
const validEmailCharacters = /^\S+@\S+$/;

export default class UserService {
    static validateUsername(username: string): boolean {
        if (!username) return false;
        if (!this.validateUsernameLength(username)) return false;
        if (!this.isValidCharacters(username)) return false;
        return true;
    }

    // make sure username is at least 4 characters long
    static validateUsernameLength(username: string): boolean {
        return username.length >= 4;
    }

    static isValidCharacters(username: string): boolean {
        return validLetters.test(username);
    }

    static validateEmail(email: string): boolean {
        if (!email) return false;
        if (!email.includes('.')) return false;
        return validEmailCharacters.test(email);
    }

    static validatePassword(password: string): boolean {
        if (!password) return false;
        return password.length >= 6;
    }
}