import React, {useEffect, useState} from "react";
import Layout from "../../layout";
import Container from "../../bulma/Container";
import { Translations as T } from '../../../res/translations';
import PageHeading from "../../bulma/PageHeading";
import Box from "../../bulma/Box";
import Columns from "../../bulma/Columns";
import Column from "../../bulma/Column";
import SectionHeading from "../../bulma/SectionHeading";
import ImageDescription from "../../bulma/ImageDescription";
import ImageLevel from "../../bulma/ImageLevel";
import {callApi} from "../../../api/ssp";
import {api} from "../../../config/config";
import errorHandler from "../../../services/errorHandler";
import {ImageDataReq} from "../../../interfaces/ImageDataReq";
import urlService from "../../../services/urlService";
import PhotoService from "../../../services/photoService";
import {OpenImageData} from "../../../interfaces/OpenImageData";
import {useNavigate, useParams} from "react-router-dom";
import KeywordPanel from "./KeywordPanel";
import ErrorType from "../../../interfaces/ErrorType";
import ErrorContainer from "../../bulma/ErrorContainer";

interface PhotoPageProps {
    lang: string;
}

const PhotoPage = ({lang}: PhotoPageProps) => {
    const [error, setError] = useState<ErrorType>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imageData, setImageData] = useState<OpenImageData>();
    const [keywords, setKeywords] = useState<string[]>([]);
    const {id} = useParams();
    const navigate = useNavigate();
    const imgSrc = 'https://storage.googleapis.com/scanstock-old-comps/comp' + id + '.JPG';

    const handleGetImageData = () => {
        if (!id) return false;
        setIsLoading(true);
        //setError({});
        callApi.get<ImageDataReq>(api.url + urlService.getImageData(id))
            .then(res => {
                setIsLoading(false);
                setImageData(res.data.imageData);
                setKeywords(res.data.keywords);
            })
            .catch(error => {
                setError(errorHandler.requestError(error));
            });
    }

    useEffect(() => {
        if (id && PhotoService.parsePhotoId(id) === 0) navigate('/404');
        handleGetImageData();
    }, [])

    if (error) {
        return (
            <Layout lang={lang}>
                <Container>
                    <ErrorContainer title={error?.title} message={error?.message} status={error?.status} />
                </Container>
            </Layout>
            );
    }

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={imageData?.title} />
                <Box>
                    <Columns>
                        <Column col="2">
                            <img src={imgSrc} />
                        </Column>
                        <Column col="2">
                            <button className="button is-medium is-primary is-fullwidth">{T[lang].photo.download}</button>
                            <div className="buttons mt-5">
                                <button className="button">{T[lang].photo.similar}</button>
                                <button className="button">Lagre</button>
                                <button className="button">Last ned prøve</button>
                                <button className="button">{T[lang].photo.viewprice}</button>
                            </div>
                            <SectionHeading><span>{imageData?.title}</span></SectionHeading>
                            <ImageDescription>
                                <p>{imageData?.description}</p>
                            </ImageDescription>
                        </Column>
                    </Columns>
                    <ImageLevel imageId={imageData?.id} autorName={imageData?.copyrightName} />

                    <SectionHeading><span>{T[lang].photo.technical}</span></SectionHeading>
                    <div className="columns">
                        <div className="column">
                            <div className="table-container table is-fullwidth">
                                <table className="table">
                                    <tr>
                                        <td><strong>File size</strong></td>
                                        <td>2.23 MB (Megabyte)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dimensions</strong></td>
                                        <td>(width) {imageData?.width} px (height) {imageData?.height} px</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Print size at 300DPI</strong></td>
                                        <td>(width) 4050 px (height) 2700 px</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="column">
                            <div className="table-container table is-fullwidth">
                                <table className="table">
                                    <tr>
                                        <td><strong>File size</strong></td>
                                        <td>2.23 MB (Megabyte)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dimensions</strong></td>
                                        <td>(width) 4050 px (height) 2700 px</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Print size at 300DPI</strong></td>
                                        <td>(width) 4050 px (height) 2700 px</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <SectionHeading><span>{T[lang].photo.keywords}</span></SectionHeading>
                    <Columns>
                        <Column col="1">
                           <KeywordPanel keywords={keywords} />
                        </Column>
                    </Columns>
                    <div className="mt-5">
                        <p className="is-size-6"><strong>Link to this image:</strong></p>
                    </div>
                </Box>
            </Container>
        </Layout>
    );
}

export default PhotoPage;
