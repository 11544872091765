import React from 'react';
import '../design/styles.scss';
import NavbarTemp from "./pages/NavbarTemp";

interface LayoutProps  {
    lang: string;
    children: React.ReactNode;
    showHeader?: boolean;
}

const Layout = ({children, lang, showHeader = true}: LayoutProps): JSX.Element => {
    return (
        <>
            {showHeader && <NavbarTemp lang={lang} />}
            <div className="container-fluid space-top-4">
                {children}
            </div>
        </>
    )
}

export default Layout
