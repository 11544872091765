
export const Translations: {[index: string]:any} = {
    no: {
        misc: {
            cancel: 'Avbryt',
            showAll: 'Vis alle',
            showLess: 'Vis færre',
            continue: 'Fortsett',
            email: 'e-post',
        },
        order: {
            order: 'Bestill',
            pricing: 'Priser',
            imageCount: 'Antall bilder',
            unitPrice: 'Pris pr bilde',
            price: 'Pris',
            buy: 'Kjøp denne',
            inCurrency: 'Prisene er oppgitt i',
            norVatInfo: 'Prisene er oppgitt uten mva.',
            twoYRule: 'Bildene må lastes ned innen 2 år etter kjøpet. Men bildene du kjøper kan brukes for alltid.',
            popluar: 'Mest kjøpt',
            ccImgAlt: 'Du kan bruke Mastercard, Visa eller American Express',
            newCustomer: 'Jeg er en ny kunde',
            existCustomer: 'Jeg er allerede en kunde',
            notfound: 'Dette produktet fant vi ikke',
        },
        login: {
            title: 'Logg inn',
            username: 'Brukernavn',
            password: 'Passord',
            repeatpassword: 'Gjenta passord',
            errors: {
                pworuname: 'Feil brukernavn eller passord.'
            }
        },
        auth: {
            resetpassword: 'Glemt passord',
            changePassword: 'Endre passord',
            passwordUpdated: 'Passordet er oppdatert',
            enterNewPassword: 'Skriv inn ditt nye passord',
            usernameRequired: 'Du må oppgi et brukernavn',
            passwordUpdatedMsg: 'Passordet er oppdatert. Du kan nå logge inn med ditt nye passord.',
            resetinfo: 'Skriv inn brukernavn eller epost. Du vil etterpå motta en epost med instruksjoner om hvordan du kan endre passordet ditt.',
            resetemailinfo: 'Du må bruke brukernavn hvis du har flere tilganger med samme epost.',
            multipleAccounts: 'Du har flere brukerkontoer med samme epost. For å endre passord må du bruke brukernavn.',
            resetUsed: 'Denne lenken er allrede brukt. Du kan ikke bruke den igjen.',
            passwordResetRequested: 'Passord endring er sendt',
            passwordResetRequestedMsg: 'En epost med instruksjoner om hvordan du kan endre passordet ditt er sendt. Sjekk søppelpost om du ikke ser den i innboksen.',
        },
        register: {
            title: 'Opprett ny brukerkonto',
            titleDone: 'Velkommen som ny bruker på ScanStockphoto.com!',
            titleDoneMsg: 'Test din ny konto ved å ',
            actionMsg: 'logge inn',
            actionPurchase: 'Skal du laste ned bilder må du først',
            actionPurchaseLink: 'kjøpe nedlastingskreditter',
            username: 'Brukernavn',
            usernameInfo: 'Brukernavn må være minst 4 tegn',
            usernameNotValid: 'Brukernavn må være minst 4 tegn og kan kun inneholde bokstaver, tall, @, bindestrek, understrek og punktum (@, -, _ og .)',
            countryMissing: 'Du må velge et land',
            emailRequired: 'Du må oppgi en epost',
            usernameIsValid: 'Brukernavn er gyldig',
            password: 'Passord',
            email: 'e-post',
            country: 'Land',
            selectCountry: 'Velg land',
            usernameAlreadyExists: 'Brukernavn er allerede i bruk',
            usernameIsAvailable: 'Brukernavn er ledig',
            usernameTooShort: 'Brukernavn må være minst 4 tegn',
            passwordTooShort: 'Passord må være minst 6 tegn',
            errors: {
                pworuname: 'Feil brukernavn eller passord.'
            }
        },
        frontpage: {
            title: 'Kjøp illustrasjons bilder for bare kr 25 pr stk',
            subTitle: 'Velg blant millioner i høy oppløsning',
            signUp: 'Registrer deg',
        },
        downloadpage: {
            title: 'Nedlasting histories',
            subTitle: 'Velg blant millioner i høy oppløsning',
        },
        account: {
            title: 'Brukerkonto',
            subTitle: 'Velg blant millioner i høy oppløsning',
        },
        navbar: {
            pricing: 'Priser',
        },
        photo: {
            imageId: 'Bilde nr',
            download: 'Last ned',
            similar: 'Lignende bilder',
            viewprice: 'Vis priser',
            technical: 'Teknisk informasjon',
            keywords: 'Nøkkelord',
        },
        error: {
            network: 'Nettverksfeil - mangler forbindelse med server',
            networkmsg: 'Nettverksforbindelse med server mangler. Enten er vår server ikke tilgjengelig eller så mangler du internettforbindelse.',
            missingData: 'Alle felter som er merket med * må fylles ut',
            emailNotValid: 'Eposten er ikke gyldig',
            serverErrorTitle: 'Serverfeil',
            serverError: 'Serverfeil - prøv igjen senere eller kontakt oss. Her har vi dessverre klønet litt med systemet vårt.',
        }
    },
    en: {
        misc: {
            cancel: 'Cancel',
            showAll: 'Show all',
            showLess: 'Show less',
            continue: 'Continue',
            email: 'email',
        },
        order: {
            order: 'Order',
            pricing: 'Pricing',
            imageCount: 'Images',
            unitPrice: 'Image price',
            price: 'Price',
            buy: 'Buy',
            inCurrency: 'Prices given in',
            norVatInfo: 'Prices given with out Norwegian V.A.T.',
            twoYRule: 'Images must be download with in 2 years from purchase. But your license lasts forever',
            popluar: 'Most popular',
            ccImgAlt: 'You can use Mastercard, Visa ,or American Express',
            newCustomer: 'I\'m a new customer',
            existCustomer: 'I\'m a returning customer',
            notfound: 'We could not find this product',
        },
        login: {
            title: 'Log in',
            username: 'Username',
            password: 'Password',
            repeatpassword: 'Repeat password',
            errors: {
                pworuname: 'Wrong username or password.'
            }
        },
        auth: {
            resetpassword: 'Reset password',
            changePassword: 'Change password',
            passwordUpdated: 'Password updated',
            enterPassword: 'Please enter your new password',
            usernameRequired: 'You must enter a username',
            passwordUpdatedMsg: 'Your password has been updated. You can now log in with your new password.',
            resetinfo: 'Enter your username or e-mail address. You will receive a link to create a new password via email.',
            resetemailinfo: 'You must use your username if you have multiple accounts with the same e-mail.',
            multipleAccounts: 'You have multiple user accounts with the same e-mail. To change your password, you must use your username.',
            resetUsed: 'This link has already been used. You cannot use it again.',
            passwordResetRequested: 'Password reset requested',
            passwordResetRequestedMsg: 'You will receive an e-mail with instructions on how to reset your password. Check your spam folder if you do not see it in your inbox.',
        },
        register: {
            title: 'Register',
            titleDone: 'Welcome as a new user to Scanstockphoto.com',
            titleDoneMsg: 'Test your new account by ',
            actionMsg: 'logging in',
            actionPurchase: 'To download images. You must first',
            actionPurchaseLink: 'purchase download credits',
            username: 'Username',
            usernameInfo: 'Username must be at least 4 characters',
            usernameNotValid: 'Username must be at least 4 characters and can only contain letters, numbers, @, underscores, dashes, dots  (_, -, . and @)',
            countryMissing: 'Country is missing. Please select the country you live in',
            usernameIsValid: 'Username is valid',
            password: 'Password',
            email: 'e-mail',
            country: 'Country',
            selectCountry: 'Select country',
            emailRequired: 'You must enter an e-mail',
            usernameAlreadyExists: 'Username already exists',
            usernameIsAvailable: 'Username is available',
            usernameTooShort: 'Username must be at least 4 characters',
            passwordTooShort: 'Password must be at least 6 characters',
            errors: {
                pworuname: 'Wrong username or password.'
            }
        },
        frontpage: {
            title: 'Buy low price high-resolution images for only Kr 25 each',
            subTitle: 'Choose from millions of curated images',
            signUp: 'Sign up',
        },
        downloadpage: {
            title: 'Downloads',
            subTitle: 'Velg blant millioner i høy oppløsning',
        },
        account: {
            title: 'Account',
            subTitle: 'Velg blant millioner i høy oppløsning',
        },
        navbar: {
            pricing: 'Pricing',
        },
        photo: {
            imageId: 'Image id',
            download: 'Download',
            similar: 'Similar images',
            viewprice: 'View prices',
            technical: 'Technical',
            keywords: 'Keywords',
        },
        error: {
            nettwork: 'Newtwork error - missing connection with server',
            networkmsg: 'Missing connection with server. Either our server is down or you are missing internet connection.',
            missingData: 'All fields marked with * must be filled in',
            emailNotValid: 'E-mail is not valid',
            serverErrorTitle: 'Server error',
            serverError: 'Server error - try again later or contact us. We have messed up our system.',
        }
    },
}
