import React from "react";

interface ImageDescriptionProps {
    children?: JSX.Element|JSX.Element[];
}

const ImageDescription = ({children}: ImageDescriptionProps) =>
    <p className="mb-5">
        {children}
    </p>

export default ImageDescription;
