import React from "react";

interface ColumnsProps {
    children?: JSX.Element|JSX.Element[];
}

const Columns = ({children}: ColumnsProps) =>
    <div className="columns">
        {children}
    </div>

export default Columns;
