import {pricing} from "../res/Pricing";
import {Product} from "../types/Product";

export default class OrderService {

    static getProductData = (productId: string): Product | undefined => {
        const productData = pricing.find((product) => {
            return product.productid === productId;
        });
        return productData;
    }
}
