import React from "react";
import Label from "./Label";

interface DropDownOption {
    value: string;
    label: string;
}
interface DropdownProps {
    caption?: string;
    value: string;
    options: DropDownOption[];
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const Dropdown = ({ caption = '', options, onChange, value }: DropdownProps) : JSX.Element => {
    return (
        <>
        {caption && <Label caption={caption} />}
            <div className="control">
                <div className="select is-multiple">
                    <select
                        onChange={onChange}
                        value={value}
                    >
                        {
                            options.map((option) =>
                                <option value={option.value}>{option.label}</option>
                            )
                        }
                    </select>
                </div>
            </div>
        </>
    );
}

export default Dropdown;
