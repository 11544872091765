import React from "react";
import Column from "../../bulma/Column";

const PaymentOptions = (): JSX.Element => {

    return (
        <Column col="1">
            <p className="has-text-weight-semibold">Please select method of payment</p>
            <p>Payment card</p>
            <p>Invoice</p>
        </Column>
    );

}

export default PaymentOptions;
