import React from "react";

interface PageHeadingProps {
    heading?: string;
}

const PageHeading = ({heading}: PageHeadingProps): JSX.Element =>
    <h1 className="title is-2 is-spaced">
        {heading}
    </h1>

export default PageHeading;
