import {portal} from "../config/config";

export const ROUTES: {[index: string]:any} = {
    en: {
        index: '/',
        login: '/login',
        logout: '/logout',
        account: '/account',
        photo: '/photo/',
        register: '/register/',
        pricing: '/pricing',
        order: '/order/',
    },
    no: {
        index: '/no',
        login: '/no/login',
        logout: '/no/logout',
        account: '/no/account',
        photo: '/no/photo/',
        register: '/no/register/',
        pricing: '/no/pricing',
        order: '/no/order/',
    }
}

export const API_ROUTES = {
    login: '/login',
    user: {
        register: '/user/register',
        resetpassword: '/user/resetpassword',
        resetpasswordrequest: '/user/resetpasswordrequest',
        country: '/user/country',
    },
    account: {
        downloads: '/v1/account/downloads'
    },
    auth: {
        resetpassword: '/auth/resetpassword',
    },
}

export const PORTAL_RESET_POST = portal.url + '/auth/resetpassword';
