import React from "react";

interface SectionHeadingProps {
    children?: JSX.Element|JSX.Element[];
}

const SectionHeading = ({children}: SectionHeadingProps) =>
    <h4 className="is-size-5 mb-1">
        {children}
    </h4>

export default SectionHeading;
