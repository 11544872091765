import React from 'react';
import Container from '../../bulma/Container';
import PageHeading from '../../bulma/PageHeading';
import Layout from '../../layout';
import {Translations as T} from '../../../res/translations';

interface AccountPageProps {
    lang: string;
}

const AccountPage = ({lang}: AccountPageProps) => {
    return (
        <Layout lang={lang} showHeader>
            <Container>
                <PageHeading heading={T[lang].account.title} />
            </Container>
        </Layout>
    );
}

export default AccountPage;
