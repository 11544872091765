import countryList from '../res/countries.json';

export default class CountryService {

    static getCountryList = (initialValue: string, initialText: string) => {
        const list = countryList.map((country, index) => {
            return {value: country.code, label: country.name};
        });
        list.unshift({value: initialValue, label: initialText});
        return list;
    }
}
