import React from "react";

interface NotificationProps {
    children?: JSX.Element;
}

const Notification = ({children}: NotificationProps): JSX.Element =>
    <div className="notification is-warning">
        {children}
    </div>

export default Notification;
