import React from "react";
import {Product} from "../../../types/Product";
import {v4 as uuidv4} from "uuid";
import PriceCell, {PriceCellKind} from "./PriceCell";
import {mostPopular} from "../../../res/Pricing";

interface PriceRowProps {
    price: Product;
    lang: string;
}

const PriceRow = ({price, lang}: PriceRowProps): JSX.Element =>

    <tr key={uuidv4()} className={price.quantity === mostPopular ? "has-background-success-light " : ""}>
        <PriceCell price={price} kind={PriceCellKind.Qty} lang={lang} />
        <PriceCell price={price} kind={PriceCellKind.UnitPrice} lang={lang} />
        <PriceCell price={price} kind={PriceCellKind.Price} lang={lang} />
        <PriceCell price={price} kind={PriceCellKind.Order} lang={lang} />
    </tr>

export default PriceRow;
