import React, {useEffect, useState} from "react";
import axios from "axios";
import Container from "../bulma/Container";
import PageHeading from "../bulma/PageHeading";
import SimpleCard from "../bulma/SimpleCard";
import {Translations as T} from '../../res/translations';
import Layout from "../layout";
import Field, {helpTextVariant} from "../input/Field";
import {api} from "../../config/config";
import {API_ROUTES} from "../../res/routes";
import errorHandler from "../../services/errorHandler";
import ErrorType from "../../interfaces/ErrorType";
import ErrorContainer from "../bulma/ErrorContainer";
import UserService from "../../services/userService";
import Dropdown from "../input/Dropdown";
import {CountryResponse} from "../../interfaces/CountryResponse";
import CountryService from "../../services/countryService";

interface RegisterPageProps {
    lang: string;
}

enum UsernameAvailability {
    AVAILABLE,
    NOT_AVAILABLE,
    NOT_SET,
    NOT_VALID,
    IS_VALID
}

const RegisterPage = ({lang}: RegisterPageProps) => {
    const [error, setError] = useState<ErrorType | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [country, setCountry] = useState('');
    const [usernameAvailability, setUsernameAvailability] = useState(UsernameAvailability.NOT_SET);

    const handleRegister = () => {
        if (!validate()) return;
        setIsLoading(true);
        const payload = {
            "username": username,
            "password": password,
            "email": email,
            "country": country,
        }

        axios.post(api.url + API_ROUTES.user.register, payload)
            .then(function (response) {
                setIsLoading(false);
                setIsDone(true);
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(errorHandler.customError(T[lang].error.missingData));
                            return false;
                        case 409:
                            setError(errorHandler.customError(T[lang].register.usernameAlreadyExists));
                            return false;
                    }
                }
                setError(errorHandler.requestError(error, lang));
            });
    }

    const validate = (usernameOnly = false): boolean => {
        setError(null);
        if (!UserService.validateUsernameLength(username)) {
            setError(errorHandler.customError(T[lang].register.usernameTooShort));
            return false;
        }
        if (!validateUsername()) return false;
        if (usernameOnly) return true;
        if (password && !UserService.validatePassword(password)) {
            setError(errorHandler.customError(T[lang].register.passwordTooShort));
            return false;
        }
        if (!email) {
            setError(errorHandler.customError(T[lang].register.emailRequired));
            return false;
        }
        if (!UserService.validateEmail(email)) {
            setError(errorHandler.customError(T[lang].error.emailNotValid));
            return false;
        }
        if (!password) {
            setError(errorHandler.customError(T[lang].error.missingData));
            return false;
        }
        if (!country) {
            setError(errorHandler.customError(T[lang].register.countryMissing));
            return false;
        }
        if (usernameAvailability === UsernameAvailability.NOT_AVAILABLE) {
            setError(errorHandler.customError(T[lang].register.usernameAlreadyExists));
            return false;
        }
        return true;
    }

    const handleCheckUsername = () => {
        if (!validate(true)) return;
        setIsLoading(true);
        axios.get(api.url + API_ROUTES.user.register + '/' + username)
            .then(function (response) {
                setIsLoading(false);
                setUsernameAvailability(UsernameAvailability.AVAILABLE);
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response && error.response.status === 409) {
                    setUsernameAvailability(UsernameAvailability.NOT_AVAILABLE);
                    setError(errorHandler.customError(T[lang].register.usernameAlreadyExists));
                } else {
                    setError(errorHandler.requestError(error, lang));
                }
            });
    }

    const handleGetCountryByIp = () => {
        if (country) return;
        axios.get<CountryResponse>(api.url + API_ROUTES.user.country)
            .then(function (response) {
                setCountry(response.data.country_code);
            });
    }

    useEffect(() => {
        handleGetCountryByIp();
    }, []);

    const validateUsername = ():boolean => {
        if (!UserService.validateUsername(username)) {
            setError(errorHandler.customError(T[lang].register.usernameNotValid));
            return false;
        }
        return true;
    }

    const isValidPassword = (): boolean => {
        if (!password) return true;
        return UserService.validatePassword(password);
    }

    const getHelpText = (): string => {
        switch (usernameAvailability) {
            case UsernameAvailability.AVAILABLE:
                return T[lang].register.usernameIsAvailable;
            case UsernameAvailability.NOT_AVAILABLE:
                return T[lang].register.usernameAlreadyExists;
            case UsernameAvailability.NOT_SET:
                return T[lang].register.usernameInfo;
            case UsernameAvailability.NOT_VALID:
                return T[lang].register.usernameNotValid;
            case UsernameAvailability.IS_VALID:
                return T[lang].register.usernameIsValid;
        }
        return '';
    }

    const usernameTyping = () => {
        if (username.length >= 0 && username.length <= 3) {
            setUsernameAvailability(UsernameAvailability.NOT_SET);
            return;
        }
        if (!UserService.validateUsername(username)) {
            setUsernameAvailability(UsernameAvailability.NOT_VALID);
            return;
        }
        setUsernameAvailability(UsernameAvailability.IS_VALID);
        setError(null);
    }

    const passwordTyping = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!password || isValidPassword()) {
            setError(null);
        }
        if (event.key === 'Enter') {
            handleRegister();
        }
    }

    const getCountryList = () => CountryService.getCountryList('', T[lang].register.selectCountry);

    const getHelpTextStatus = (): helpTextVariant => {
        switch (usernameAvailability) {
            case UsernameAvailability.AVAILABLE:
                return helpTextVariant.success;
            case UsernameAvailability.NOT_AVAILABLE:
            case UsernameAvailability.NOT_VALID:
                return helpTextVariant.danger;
        }
        return helpTextVariant.info;
    }

    const success =
        <SimpleCard>
            <div className="content is-medium">
                <p>{T[lang].register.titleDoneMsg}<a href="https://scanstockphoto.com/signin.php">{T[lang].register.actionMsg}.</a></p>
                <p>{T[lang].register.actionPurchase} <a href="https://scanstockphoto.com/buy.php">{T[lang].register.actionPurchaseLink}.</a></p>
                <div>
                    <a href="https://scanstockphoto.com/signin.php" className="button is-primary">
                        <button className="button is-primary">
                            {T[lang].login.title}
                        </button>
                    </a>
                </div>
            </div>
        </SimpleCard>

    const regForm =
        <SimpleCard>
            <Field
                caption={T[lang].register.username}
                onChange={(e) => setUsername(e.currentTarget.value)}
                onBlur={() => handleCheckUsername()}
                onKeyUp={() => usernameTyping()}
                value={username}
                isErrror={usernameAvailability === UsernameAvailability.NOT_AVAILABLE}
                helpText={getHelpText()}
                helpTextStatus={getHelpTextStatus()}
                variant="username"
                isRequired={true}
            />
            <Field
                caption={T[lang].register.email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                value={email}
                isErrror={email !== '' && !UserService.validateEmail(email)}
                variant="email"
                isRequired={true}
            />
            <Field
                caption={T[lang].register.password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                onKeyUp={(event) => passwordTyping(event)}
                value={password}
                variant="password"
                isErrror={password !== '' && !UserService.validatePassword(password)}
                helpText={T[lang].register.passwordTooShort}
                helpTextStatus={isValidPassword() ? helpTextVariant.info : helpTextVariant.danger}
                isRequired={true}
            />
            <Dropdown
                value={country}
                caption={T[lang].register.country + ' *'}
                options={getCountryList()}
                onChange={(e) => setCountry(e.currentTarget.value)}
            />
            <div className="buttons mt-5">
                <button
                    className={'button is-primary' + (isLoading ? ' is-loading' : '')}
                    onClick={() => handleRegister()}
                    disabled={isLoading}
                >
                    {T[lang].register.title}
                </button>

                <button className="button">
                    {T[lang].misc.cancel}
                </button>
            </div>
        </SimpleCard>

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={isDone ? T[lang].register.titleDone : T[lang].register.title} />
                {
                    isDone ? success : regForm
                }
                <>
                    {
                    error &&
                        <ErrorContainer title={error?.title} message={error?.message} status={error?.status} />
                    }
                </>
            </Container>
        </Layout>
    );
}

export default RegisterPage;
