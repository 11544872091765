import React from 'react';
import { Translations as T } from '../../res/translations';
import {Link} from "react-router-dom";
import {ROUTES} from "../../res/routes";

interface NavBarTempProps {
    lang: string;
}

const NavbarTemp = ({lang}: NavBarTempProps) =>
    <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <div className="navbar-item">
                <a href="https://scanstockphoto.com/">
                    <img alt="logo" src="https://storage.googleapis.com/scanstock-static/main_logo_60.png" />
                </a>
            </div>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
                <div className="navbar-item">
                    <a href="https://scanstockphoto.com/buy.php">{T[lang].navbar.pricing}</a>
                </div>
            </div>

            <div className="navbar-end">
                <div className="navbar-item">
                    <div className="buttons">
                        <a className="button is-light" href="https://scanstockphoto.com/signin.php">
                            {T[lang].login.title}
                        </a>
                        <Link className="button is-primary" to={ROUTES[lang].register}>
                            {T[lang].frontpage.signUp}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>

export default NavbarTemp;
