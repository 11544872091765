import Notification from "./Notification";
import React from "react";


interface ErrorContainerProps {
    title?: string;
    message?: string;
    status?: string;
}

const ErrorContainer = ({title, message, status}: ErrorContainerProps) =>
      <div className="error-container">
              <Notification>
                  <>
                      <h2 className="is-size-4">{title}</h2>
                      <p className="pt-3">{message}</p>
                      {
                          status &&
                            <p className="pt-5 has-text-weight-semibold">({status})</p>
                      }
                  </>
              </Notification>
      </div>

export default ErrorContainer;
