import React from "react";
import Layout from "../../layout";
import Container from "../../bulma/Container";
import PageHeading from "../../bulma/PageHeading";
import {Translations as T} from "../../../res/translations";
import OrderNoUser from "./OrderNoUser";
import {useParams} from "react-router-dom";
import authService from "../../../services/authService";
import OrderService from "../../../services/orderService";
import Notification from "../../bulma/Notification";
import OrderPanel from "./OrderPanel";

interface OrderPageProps {
    lang: string;
}

const OrderPage = ({lang}: OrderPageProps) => {
    const {product} = useParams();
    const productData = product ? OrderService.getProductData(product) : null;
    const isLoggedIn = authService.isLogin();
    const isProductFound = !!productData;

    return (
        <Layout lang={lang}>
            <Container>
                <PageHeading heading={T[lang].order.order} />
                <>
                    {
                        !isProductFound &&
                        <Notification>
                            <span>We did not find this product</span>
                        </Notification>
                    }
                    {!isLoggedIn && isProductFound && <OrderNoUser lang={lang} product={productData?.productid} />}

                    {isLoggedIn && isProductFound && <OrderPanel lang={lang} product={productData} />}
                </>
            </Container>
        </Layout>
    );
};

export default OrderPage;
