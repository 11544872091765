import React from 'react';
import Layout from "../layout";
import Container from "../bulma/Container";
import PageHeading from "../bulma/PageHeading";

const PageNotFound = (): JSX.Element => {
    document.title = "404 Page not found";
    return (
        <Layout lang="en" showHeader={true}>
            <Container>
                <PageHeading heading="404 Page not found" />
                <p>We did not find this page.</p>
            </Container>
        </Layout>
    );
}

export default PageNotFound;
