import {googleImg} from "../config/config";

export default class urlService {

    static getThumbSm(imageId: number): string {
        return googleImg.thumbSm + 'thumb' + imageId + '.JPG';
    }

    static getImageData(imageId: string): string {
        return '/' + imageId.toString() + '/image';
    }

}
