import React, {useState} from "react";
import Label from "./Label";
import Envelope from "../icons/Envelope";
import UserIcon from "../icons/UserIcon";
import Lock from "../icons/Lock";
import Eye from "../icons/Eye";

const IconHasLeft = 'has-icons-left';
//const IconHasRight = 'has-icons-right';
//const IconHasRightLeft = 'has-icons-left has-icons-right';

export enum helpTextVariant {
    success = 'is-success',
    danger = 'is-danger',
    info = 'is-info'
}

interface FieldProps {
    caption: string;
    helpText?: string;
    helpTextStatus?: helpTextVariant;
    variant: string;
    value: string;
    isErrror?: boolean;
    isRequired?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Field = ({
                   caption, helpText = '',
                   helpTextStatus = helpTextVariant.info,
                   isErrror = false,
                   isRequired = false,
                   variant,
                   value,
                   onChange,
                   onKeyDown,
                   onKeyUp,
                   onBlur
    }: FieldProps): JSX.Element => {
    let initialType = 'text';

    switch (variant) {
        case 'email':
            initialType = 'email';
            break;
        case 'password':
            initialType = 'password';
    }

    const [type, setType] = useState(initialType);
    let fieldIconClass = '';
    let icons = <></>;
    let affixBtn = <></>;

    const handleShowPassword = () => {
        setType(type === 'text' ? 'password' : 'text');
    }

    if (variant === 'email') {
        fieldIconClass = IconHasLeft;
        icons = <span className="icon is-small is-left">
                    <Envelope />
                </span>
    }

    if (variant === 'username') {
        fieldIconClass = IconHasLeft;
        icons = <span className="icon is-small is-left">
                    <UserIcon />
                </span>
    }

    if (variant === 'password') {
        fieldIconClass = IconHasLeft;
        icons =
            <>
                <span className="icon is-small is-left">
                    <Lock />
                </span>
            </>
        affixBtn =
            <p className="control">
                <button
                    type="button"
                    onClick={() => handleShowPassword()}
                    className={"button" + (type === 'text' ? " is-info" : "")}
                >
                    <Eye />
                </button>
            </p>
    }

    if (isRequired) {
        caption += ' *';
    }

    return (
        <div className="field">
            <Label caption={caption} />
            <div className="control">
                <div className="field has-addons mt-1">
                    <div className={"control is-expanded " + fieldIconClass}>
                        <input
                            className={"input " + (isErrror ? 'is-danger' : '')}
                            type={type}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onKeyUp={onKeyUp}
                            onBlur={onBlur}
                            value={value}
                        />
                        {icons}
                    </div>
                    {affixBtn}
                </div>
                <p className={"help " + helpTextStatus}>{helpText}</p>
            </div>
        </div>
    );
}

export default Field;
