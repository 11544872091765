import React from "react";

interface BoxProps {
    children?: JSX.Element|JSX.Element[];
}

const Box = ({children}: BoxProps): JSX.Element =>
    <div className="box">
        {children}
    </div>

export default Box;
